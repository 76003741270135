<template>
  <div class="galery-page ">
    <div class="box-container q-pa-xl ">
      <div class="card bg-grey-2">
        <div class="pic">
          <img
            :src="'./img/guru/avatar.png'"
            alt="profile-picture"
          />
        </div>
        <div class="content">
          <h3 class="title">
            <a>Agus SP. Amikaton, S.E,M.M</a>
          </h3>
          <span>Kepala Sekolah</span>
        </div>
      </div>
    </div>
    <div class=" q-pb-md q-pt-xl text-center text-h5">Guru dan Staff SMP AFBS</div>
    <div class="flex row wrap justify-center q-pb-xl">
      <div class="box-container2 q-px-lg " v-for="x in staff" :key="x.id">
        <div class="card bg-grey-2" >
          <div class="pic">
            <img v-if="x.jenis_kelamin == 'L'"
              :src="'./img/guru/avatar.png'"
              alt="profile-picture"
            />
            <img v-else
              :src="'./img/guru/avatar2.png'"
              alt="profile-picture"
            />
          </div>
          <div class="content">
            <h3 class="title">
              <a>{{x.nama}}</a>
            </h3>
            <span>{{x.jabatan}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import VueGallery from 'vue-gallery';

export default {
  name: 'Home',
  components: {
    'gallery': VueGallery
  },
  data(){
    return{
      slide: 'style',
      lorem: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque voluptatem totam, architecto cupiditate officia rerum, error dignissimos praesentium libero ab nemo.',
      index: null,
      staff: []
    }
  },
  mounted(){

    this.$http.get("/daftar_staff", {})
    .then(result => {
      this.staff = result.data
    });


  },
  methods:{

  },
  computed:{
    maxHeight(){
      return this.$q.screen.height
    }
  }
}
</script>
<style lang="scss" scoped>

.box-container {
  display: flex;
  // width: 100%;
  height: 400px; // 100vh;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.box-container .card {
  position: relative;
  width: 290px;
  height: 350px;
  border-radius: 10px;
  overflow: hidden;
  // background: #E1F5FE;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.07), 0 6px 20px 0 rgba(0, 0, 0, 0.07);
}
.box-container .card:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 0;
  background: #64b3f4;
  transition: all 0.5s ease;
}
.box-container .card .pic {
  position: relative;
  left: 50%;
  top: 15%;
  transform: translateX(-50%);
  width: 180px;
  height: 180px;
  overflow: hidden;
  transition: all 0.5s ease;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.07), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
}
.box-container .card .pic img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.box-container .card .content {
  margin-top: 90px;
  text-align: center;
  padding: 0 30px;
  line-height: 0.95;
  transition: margin-top 0.5s ease;
  cursor: pointer;
}
.box-container .card .content .title {
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #0b8cc4;
}
.box-container .card .content span {
  margin: 40px 0;
  font-size: 12px;
  letter-spacing: 1.5px;
  color: #78909C;
}
.box-container .card .content p {
  margin: 20px 0;
  font-size: 12px;
  line-height: 1.6;
  color: #b0b0b0;
}
h3 {
  line-height: 1rem;
}






.box-container2 {
  display: flex;
  height: 250px;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.box-container2 .card {
  position: relative;
  width: 200px;
  height: 230px;
  border-radius: 10px;
  overflow: hidden;
  // background: #E1F5FE;  
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.07), 0 6px 20px 0 rgba(0, 0, 0, 0.07);
}
.box-container2 .card .pic {
  position: relative;
  left: 50%;
  top: 15%;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
  overflow: hidden;
  transition: all 0.5s ease;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.07), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
}
.box-container2 .card .pic img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.box-container2 .card .content {
  margin-top: 60px;
  text-align: center;
  padding: 0 30px;
  line-height: 0.95;
  transition: margin-top 0.5s ease;
  cursor: pointer;
}
.box-container2 .card .content .title {
  margin-bottom: 5px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #0b8cc4;
}
.box-container2 .card .content span {
  margin: 20px 0;
  font-size: 12px;
  letter-spacing: 1.5px;
  color: #78909C;
  // font-style: italic;
}
.box-container2 .card .content p {
  margin: 10px 0;
  font-size: 12px;
  line-height: 1.6;
  color: #b0b0b0;
}
</style>
